import React from "react"
import { Field, getIn, useFormikContext } from "formik"
import { conditionDropdownOptions, conditionOperatorOptions } from "../../models/RoutingRuleDropdownData"
import { conditionOperators, ICondition } from "../../models/RoutingRuleSetModel"
import { Input, Select } from "../../../../_metronic/_partials/controls"

interface ConditionEditProps {
    conditionFieldName: string
}

const splitOnComma = (commaSeparatedString: string) => {
    return commaSeparatedString.split(',').map((x) => x.trim()).filter((x) => x && x !== '')
}

const RoutingRuleConditionEditRow: React.FC<ConditionEditProps> = ({ conditionFieldName }) => {
    const { setFieldValue, setFieldTouched, values } = useFormikContext()
    const condition = getIn(values, conditionFieldName) as ICondition
    const conditionDropdownOption = conditionDropdownOptions.find((x) => x.variableName.toLowerCase() === condition.variableName?.toLowerCase())

    const firstEvaluationValue = (condition.evaluationValues) ? (condition.evaluationValues[0] ?? "") : ""
    const commaSeparatedEvaluationValues =
        (condition.evaluationValues && (Array.isArray(condition.evaluationValues))
            ? condition.evaluationValues.join(", ")
            : (condition.evaluationValues ?? ""))

    const noInputField = condition.conditionOperator === conditionOperators.isAny
    const freeTextInput = !noInputField && (!conditionDropdownOption || !conditionDropdownOption.evaluationValues || conditionDropdownOption.evaluationValues.length === 0)
    const multiSelectDropdown = !freeTextInput && (condition.conditionOperator === conditionOperators.in || condition.conditionOperator === conditionOperators.isSubsetOf)
    const singleSelectDropdown = !multiSelectDropdown && !noInputField && !freeTextInput

    const handleConditionOperatorChange = async (changeEvent: any) => {
        await setFieldValue(`${conditionFieldName}.conditionOperator`, changeEvent.target.value)
        setFieldTouched(`${conditionFieldName}.conditionOperator`, true)
        if (changeEvent.target.value === conditionOperators.isAny) {
            //IsAny cannot take any EvaluationValues. Clear them to avoid spurious validation errors
            await setFieldValue(`${conditionFieldName}.evaluationValues`, [])
            setFieldTouched(`${conditionFieldName}.evaluationValues`, true)
        }
        if (changeEvent.target.value === conditionOperators.equal ||
            changeEvent.target.value === conditionOperators.equalOrGreater ||
            changeEvent.target.value === conditionOperators.equalOrLesser) {
            //Equal... only takes a single EvaluationValue. Trim them to avoid spurious validation errors
            await setFieldValue(`${conditionFieldName}.evaluationValues`, condition.evaluationValues?.slice(0, 1))
            setFieldTouched(`${conditionFieldName}.evaluationValues`, true)
        }
    }

    const handleVariableNameChange = async (changeEvent: any) => {
        await setFieldValue(`${conditionFieldName}.variableName`, changeEvent.target.value)
        setFieldTouched(`${conditionFieldName}.variableName`, true)
        //EvaluationValues are unique to the variable, so clear them on variable change.
        await setFieldValue(`${conditionFieldName}.evaluationValues`, [])
        setFieldTouched(`${conditionFieldName}.evaluationValues`, true)
    }

    return (
        <>
            <div className="col-3 form-group mb-3">
                <Field
                    component={Select}
                    name={`${conditionFieldName}.variableName`}
                    type="string"
                    value={condition.variableName?.toLowerCase()}
                    onChange={handleVariableNameChange}
                >
                    <option key={"select"} value="">select variable</option>
                    {conditionDropdownOptions.map(option => (
                        <option key={option.variableName} value={option.variableName.toLowerCase()}>{option.variableName}</option>
                    ))}
                </Field>
            </div>
            <div className="col-2 form-group mb-3">
                <Field
                    as="select"
                    name={`${conditionFieldName}.conditionOperator`}
                    className="form-control"
                    onChange={handleConditionOperatorChange}
                >
                    {conditionOperatorOptions.map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </Field>
            </div>
            <div className="col-5 form-group mb-3">
                {noInputField && <div />}
                {freeTextInput && <Field
                    component={Input}
                    name={`${conditionFieldName}.evaluationValues`}
                    type="string"
                    value={commaSeparatedEvaluationValues}
                    onBlur={async (changeEvent: any) => {
                        await setFieldValue(`${conditionFieldName}.evaluationValues`,
                            splitOnComma(changeEvent.target.value))
                        setFieldTouched(`${conditionFieldName}.evaluationValues`, true)
                    }}
                />}
                {multiSelectDropdown && <Field
                    component={Select}
                    name={`${conditionFieldName}.evaluationValues`}
                    type="string"
                    multiple
                >
                    {(conditionDropdownOption?.evaluationValues ?? []).map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </Field>}
                {singleSelectDropdown && <Field
                    component={Select}
                    name={`${conditionFieldName}.evaluationValues`}
                    type="string"
                    value={firstEvaluationValue}
                    onChange={async (changeEvent: any) => {
                        await setFieldValue(`${conditionFieldName}.evaluationValues`,
                            [changeEvent.target.value])
                        setFieldTouched(`${conditionFieldName}.evaluationValues`, true)
                    }}
                >
                    <option key={"select"} value="">{`select ${conditionDropdownOption?.variableName}`}</option>
                    {(conditionDropdownOption?.evaluationValues ?? []).map(option => (
                        <option key={option} value={option}>{option}</option>
                    ))}
                </Field>}
            </div>

        </>
    )
}

export default RoutingRuleConditionEditRow