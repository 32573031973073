import axios from "axios";
import { getConfig } from "../../modules/Config/Config";
import { IRoutingRuleSet } from "../models/RoutingRuleSetModel";
import { TestCase } from "../models/TestSuiteModel";

async function getRoutingRules(): Promise<IRoutingRuleSet> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<IRoutingRuleSet>(`${backendConfig.url}/productionsiteroutingrules?ruleSetVersion=0`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data);
}

async function postRoutingRules(ruleSet: IRoutingRuleSet): Promise<IRoutingRuleSet> {
  const backendConfig = await getConfig("backend");

  return axios
    .post<IRoutingRuleSet>(`${backendConfig.url}/productionsiteroutingrules`,
      ruleSet,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data);
}

async function getTestCases(): Promise<TestCase[]> {
  const backendConfig = await getConfig("backend");

  return axios
    .get<any[]>(`${backendConfig.url}/productionsiteselectiontests`,
      {
        headers:
        {
          "Content-Type": "application/json",
        }
      })
    .then((response) => response.data.map((x) => {
      return {
        ...x,
        id: crypto.randomUUID()
      } as TestCase
    })
    );
}

export { getRoutingRules, postRoutingRules, getTestCases };
