import { Card, CardBody, CardHeader, CardHeaderToolbar } from '../../../_metronic/_partials/controls';
import { useRoutingRuleContext } from '../RoutingRuleContext';
import RoutingRulesActionCard from './RoutingRulesActionCard';
import RoutingRulesTable from './RoutingRulesTable';

type Props = {
    cardProps?: any;
}

const RoutingRulesCard = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext()

    return (
        <Card {...props.cardProps}>
            <CardHeader
                className='border-0'
                title="Routing Rules"
                {...props.cardProps}
            >
                <CardHeaderToolbar {...props.cardProps}>
                    <button
                        type="button"
                        className="border-0 bg-transparent"
                        onClick={routingRulesContext.uiEvents.showTestCasePage}>
                        <u>Show Test Cases</u>
                    </button>
                </CardHeaderToolbar>

            </CardHeader>
            <CardBody {...props.cardProps}>
                <div className='mb-6'>
                    <RoutingRulesActionCard />
                </div>
                <RoutingRulesTable />
            </CardBody>
        </Card>
    );
}

export default RoutingRulesCard;