// React bootstrap table next =>
// DOCS: https://react-bootstrap-table.github.io/react-bootstrap-table2/docs/
// STORYBOOK: https://react-bootstrap-table.github.io/react-bootstrap-table2/storybook/index.html
import React, { useEffect, useMemo, useState } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import { shallowEqual, useDispatch } from "react-redux";
import { format } from "date-fns";
import { Button, Modal } from "react-bootstrap";
import * as actions from "../redux/ProductsActions";
import {
  // getSelectRow,
  NoRecordsFoundMessage,
  PleaseWaitMessage,
} from "../../../_metronic/_helpers";
import * as uiHelpers from "../ProductsUIHelpers";
// import * as columnFormatters from "./column-formatters";
import { usePlant } from "../../components/PlantHook";
import { useProductsContext } from "../ProductsContext";
import { useAppSelector } from "../../hooks";
import { RootState } from "../../../redux/store";
import { extendEntites, formatLeadTimesCopy } from "./utils";
import { Schedule, ScheduleProductData } from "../ProductsContext";
import DeleteScheduleIcon from "../../svg/DeleteScheduleIcon";
import ExportIcon from "../../svg/ExportIcon";
import { DeleteDialog } from "../../components/DeleteDialog";
import ExpandIcon from "../../svg/ExpandIcon";
// TODO: Hiding as part of pct-12419 - will add back in next iteration
// import EditIconWithCopy from '../../products/svg/EditIconWithCopy';
// import DeleteIconWithCopy from '../../products/svg/DeleteIconWithCopy';
// import EyeIconIconWithCopy from '../../products/svg/EyeIconWithCopy';
// import ScheduleIconWithCopy from '../../products/svg/ScheduleIconWithCopy';

const ProductsTableWithSchedules = (props: any) => {
  // Products UI Context
  const plant = usePlant();
  const productsContext = useProductsContext();
  const productsUIProps = useMemo(() => {
    return {
      ids: productsContext.ids,
      setIds: productsContext.setIds,
      openEditArticleDialog: productsContext.openEditArticleDialog,
    };
  }, [productsContext]);
  // Getting curret state of products list from store (Redux)
  const currentState = useAppSelector(
    (state: RootState) => state.products,
    shallowEqual
  );
  const { entities, schedules, deleteScheduleResult } = currentState;
  const extendedEntites = extendEntites(entities);

  const filteredEntites = useMemo(() => {
    if (!props.filterQuery) return extendedEntites;
    const filterQuery = props.filterQuery.toLowerCase();
    return extendedEntites.filter((entity) => {
      const idMatch = entity.id?.toLowerCase().includes(filterQuery);
      const descMatch = entity.desc?.toLowerCase().includes(filterQuery);
      const scheduledLeadTimesMatch = entity.scheduledLeadTimes?.some(
        (scheduledLeadTime) =>
          scheduledLeadTime.name.toLowerCase().includes(filterQuery)
      );
      return idMatch || descMatch || scheduledLeadTimesMatch;
    });
  }, [props.filterQuery, extendedEntites]);

  const filteredSchedules = useMemo(() => {
    if (!filteredEntites.length) return [];
    return schedules.filter((schedule: Schedule) =>
      filteredEntites.some((entity) =>
        entity.scheduledLeadTimes?.some(
          (scheduledLeadTime) => scheduledLeadTime.id === schedule.id
        )
      )
    );
  }, [props.filterQuery, schedules]);

  // Products Redux state
  const dispatch = useDispatch();
  useEffect(() => {
    // clear selections list
    productsUIProps.setIds([]);
    // server call by queryParams
    dispatch(
      actions.fetchProductsWithSchedules(
        productsContext.productGroupName,
        plant
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    productsContext.productGroupName,
    dispatch,
    plant,
    props.hasLeadTimesUpdated,
  ]);

  const [scheduleToDelete, setScheduleToDelete] = useState<Schedule | null>(
    null
  );
  const toggleDeleteClick = (schedule: Schedule | null) => {
    setScheduleToDelete(schedule);
  };

  const toggleExportClick = (schedule: Schedule | null) => {
    handleExportSchedule(schedule?.id, schedule?.name);
  };

  const handleDeleteSchedule = (scheduleId: string | undefined) => {
    if (scheduleId) {
      dispatch(
        actions.deleteSchedule(
          plant,
          productsContext.productGroupName,
          scheduleId
        )
      );
    }
  };

  const handleExportSchedule = (
    scheduleId: string | undefined,
    scheduleName: string | undefined
  ) => {
    if (scheduleId && scheduleName) {
      dispatch(
        actions.exportSchedule(
          plant,
          productsContext.productGroupName,
          scheduleId,
          scheduleName
        )
      );
    }
  };

  const onCloseScheduleResult = () => {
    dispatch(actions.deleteScheduleClosed());
    setScheduleToDelete(null);
  };

  const scheduleColumns = filteredSchedules.map((schedule: Schedule) => {
    const headerClasses = schedule.isActive
      ? "active-header text-nowrap"
      : "text-nowrap";
    const classes = schedule.isActive ? "width-set-15 active" : "width-set-15";
    return {
      dataField: schedule.id,
      text: schedule.name,
      headerFormatter: (column: any) => {
        return (
          <>
            <span>{column.text}</span>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => toggleExportClick(schedule)}
            >
              <ExportIcon />
            </button>
            <button
              type="button"
              className="btn btn-link"
              onClick={() => toggleDeleteClick(schedule)}
            >
              <DeleteScheduleIcon />
            </button>
            <br />
            {schedule?.startDateLocal && (
              <span>
                Fr: {format(new Date(schedule.startDateLocal), "d/MM/yyyy - HH:mm")}
              </span>
            )}
            {schedule?.endDateLocal && (
              <>
                <br />
                <span>
                  to: {format(new Date(schedule.endDateLocal), "d/MM/yyyy - HH:mm")}
                </span>
              </>
            )}
            <>
              <span className="">

              </span>
            </>
          </>
        );
      },
      formatter: (cell: any, row: any) => {
        const itemSchedule = row?.scheduledLeadTimes?.length
          ? row?.scheduledLeadTimes.find(
            (item: ScheduleProductData) => item.id === schedule.id
          )
          : null;

        return itemSchedule ? (
          <div className="lighter">
            <div
              className={
                itemSchedule.isActive
                  ? "stronger active-leadtime"
                  : "inactive-leadtime"
              }
            >
              {formatLeadTimesCopy(itemSchedule?.leadTime)}
            </div>
            <div>
              {itemSchedule?.productOptionLeadTimes?.length
                ? itemSchedule.productOptionLeadTimes.map(
                  (i: Record<string, string | number>, index: number) => {
                    const isActiveOption = i.isActive
                      ? "stronger active-leadtime"
                      : "inactive-leadtime";
                    const optionLeadTimeData = formatLeadTimesCopy(
                      i.leadTime as number
                    );
                    const optionsKeyAndValue = `${i.optionKey} - ${i.optionValue}`;
                    const optionsCellLeadtime = `${optionsKeyAndValue}: ${optionLeadTimeData}`;
                    return (
                      <>
                        <div
                          className={isActiveOption}
                          key={`${optionsKeyAndValue}-${index}`}
                        >
                          {optionsCellLeadtime}
                        </div>
                      </>
                    );
                  }
                )
                : null}
            </div>
          </div>
        ) : null;
      },
      headerClasses,
      classes,
    };
  });

  // Table columns
  const columns = [
    {
      dataField: "id",
      text: "Article Code",
      sort: false,
      formatter: (cell: any, row: any) => {
        return (
          <>
            <div>{row.id}</div>
            <div className="lighter">{row.desc}</div>
          </>
        );
      },
      // TODO: put back to width-set-15 when ACTIONS column is back
      classes: "width-set-20",
    },
    {
      dataField: "leadTimes",
      text: "Lead Time",
      sort: false,
      formatter: (cell: any, row: any) => {
        const isActive = row.isActive
          ? "stronger active-leadtime"
          : "inactive-leadtime";
        return (
          <div className="lighter">
            <div className={isActive}>{formatLeadTimesCopy(row.leadTime)}</div>
            <div>
              {row.productOptionLeadTimes?.length
                ? row.productOptionLeadTimes.map(
                  (i: Record<string, string | number>, index: number) => {
                    const isActiveOption = i.isActive
                      ? "stronger active-leadtime"
                      : "inactive-leadtime";
                    const optionLeadTimeData = formatLeadTimesCopy(
                      i.leadTime as number
                    );
                    const optionsKeyAndValue = `${i.optionKey} - ${i.optionValue}`;
                    const optionsCellLeadtime = `${optionsKeyAndValue}: ${optionLeadTimeData}`;
                    return (
                      <>
                        <div
                          className={isActiveOption}
                          key={`${optionsKeyAndValue}-${index}`}
                        >
                          {optionsCellLeadtime}
                        </div>
                      </>
                    );
                  }
                )
                : null}
            </div>
          </div>
        );
      },
      // classes: 'width-set-35',
    },
    // {
    //   dataField: 'update-this-correct-data-from-contract',
    //   text: 'Active Schedule',
    //   sort: false,
    //   // classes: 'width-set-35',
    //   // TODO: we will need exact data from API and an expand button
    //   formatter: (cell: any, row: any) => (
    //     <div className='dateTimeCell'>
    //       <div>Default</div>
    //     </div>
    //   ),
    // },
    // TODO: Hiding as part of pct-12419 - will add back in next iteration
    // {
    //   dataField: 'actions',
    //   text: 'Actions',
    //   sort: false,
    //   classes: 'width-set-10',
    //   formatter: (cell: any, row: any) => (
    //     <div className='table-action-icons-container'>
    //       <button
    //         onClick={() => {}}
    //         className='table-action-icons-container-button'
    //       >
    //         <EyeIconIconWithCopy
    //           copy='All options'
    //           customStyle='table-action-icons-children'
    //         />
    //       </button>
    //       <button
    //         onClick={() => {}}
    //         className='table-action-icons-container-button'
    //       >
    //         <ScheduleIconWithCopy
    //           copy='Schedule'
    //           customStyle='table-action-icons-children'
    //           color='#7E8299'
    //         />
    //       </button>
    //       <button
    //         onClick={() => {}}
    //         className='table-action-icons-container-button'
    //       >
    //         <DeleteIconWithCopy
    //           copy='Delete'
    //           customStyle='table-action-icons-children'
    //           color='#7E8299'
    //         />
    //       </button>
    //       <button
    //         onClick={() => {}}
    //         className='table-action-icons-container-button'
    //       >
    //         <EditIconWithCopy
    //           copy='Edit'
    //           customStyle='table-action-icons-children'
    //         />
    //       </button>
    //     </div>
    //   ),
    // },
  ];

  // Table pagination properties
  return (
    <>
      <BootstrapTable
        wrapperClasses="table-responsive"
        bordered={false}
        classes="table table-head-custom table-vertical-center overflow-hidden semi-bold"
        rowClasses="text-nowrap"
        bootstrap4
        remote
        keyField="id"
        data={filteredEntites ?? []}
        columns={[...columns, ...scheduleColumns]}
        onTableChange={() => { }}
        defaultSorted={uiHelpers.defaultSorted}
      // TODO: Hiding as part of pct-12419 - will add back in next iteration
      // selectRow={getSelectRow({
      //   entities,
      //   ids: productsUIProps.ids,
      //   setIds: productsUIProps.setIds,
      //   customStyle: true,
      // })}
      >
        <PleaseWaitMessage entities={filteredEntites} />
        <NoRecordsFoundMessage entities={filteredEntites} />
      </BootstrapTable>
      {scheduleToDelete && (
        <DeleteDialog
          isOpen={scheduleToDelete}
          onHide={() => toggleDeleteClick(null)}
          onDelete={() => handleDeleteSchedule(scheduleToDelete.id)}
          scheduleId={scheduleToDelete.id}
          title={`You are about to deleting schedule ${scheduleToDelete.name}`}
          message="Deleting this schedule cannot be undone"
        />
      )}
      {deleteScheduleResult && (
        <Modal show={deleteScheduleResult !== undefined}>
          <Modal.Header closeButton>
            <Modal.Title>Delete schedule results</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              {deleteScheduleResult.message}
              <br />
              {deleteScheduleResult.deleteScheduledLeadTimeResponses
                .filter((x) => !x.message.startsWith("NO CHANGE"))
                .map((x) => (
                  <div>
                    {x.productCode} {x.message}
                  </div>
                ))}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={onCloseScheduleResult}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default ProductsTableWithSchedules;
