import { Field, FieldArray, useFormikContext } from "formik";
import { conditionOperators, conditionTypes, ICondition, IRoutingRule, logicalOperators } from "../../models/RoutingRuleSetModel";
import { logicalOperatorOptions } from "../../models/RoutingRuleDropdownData";
import RoutingRuleConditionEditRow from "./RoutingRuleConditionEditRow";

const getNewCondition = () => {
    return {
        conditionType: conditionTypes.condition,
        conditionOperator: conditionOperators.equal,
        variableName: "",
        evaluationValues: []
    } as ICondition
}

const getNewConditionGroup = () => {
    return {
        conditionType: conditionTypes.conditionGroup,
        logicalOperator: logicalOperators.and,
        conditions: [getNewCondition(), getNewCondition()]
    }
}

const RoutingRuleTriggerEditBlock = () => {
    const { values } = useFormikContext()
    const rule = values as IRoutingRule
    const trigger = rule.conditions

    return (
        <div className="border border-secondary">
            <p className="routing-rule-text-smallcaps ml-4 mt-4">Trigger</p>
            <FieldArray name="conditions.conditions">
                {({ push: addToTrigger, remove: removeFromTrigger }) => {
                    return (
                        <div className="border-top border-secondary p-4">
                            {trigger.conditions?.map((condition, conditionIndex) =>
                                <div className="form-row" key={"c-" + conditionIndex}>
                                    {(conditionIndex === 0) && <div className="col-1 mt-2 lighter">When</div>}
                                    {(conditionIndex === 1) && <div className="col-1">
                                        <Field
                                            as="select"
                                            name="conditions.logicalOperator"
                                            className="form-control px-0">
                                            {logicalOperatorOptions.map(option => (
                                                <option key={option} value={option}>{option}</option>
                                            ))}
                                        </Field>
                                    </div>}
                                    {(conditionIndex > 1) && <div className="col-1 mt-2 pl-3">{trigger.logicalOperator}</div>}
                                    {(condition.conditionType === conditionTypes.condition) ?
                                        <>
                                            <RoutingRuleConditionEditRow conditionFieldName={`conditions.conditions[${conditionIndex}]`} />
                                            <div className="col-1 form-group mb-3">
                                                <button
                                                    type="button"
                                                    className="form-control border-0 bg-transparent col-3 text-bold text-left pl-0"
                                                    onClick={() => removeFromTrigger(conditionIndex)}
                                                >
                                                    X
                                                </button>
                                            </div>
                                        </>
                                        :
                                        //ConditionGroup with internal Condition list
                                        <FieldArray name={`conditions.conditions[${conditionIndex}].conditions`}>
                                            {({ push: addToConditionGroup, remove: removeFromConditionGroup }) => {
                                                const conditionGroup = (trigger.conditions ?? [])[conditionIndex] as ICondition
                                                return (
                                                    <div className="col-11 bg-secondary rounded pl-3 pr-0 pt-4 pb-3" >
                                                        <div className="lighter">
                                                            {(condition.logicalOperator === logicalOperators.and)
                                                                ? "All of the following are true..."
                                                                : "At least one of the following is true..."}
                                                        </div>
                                                        {conditionGroup.conditions?.map((conditionGroupEntry, conditionGroupIndex) =>
                                                            <div className="form-row" key={"c2-" + conditionGroupIndex}>
                                                                {(conditionGroupIndex === 0) && <div className="col-1 mt-2 lighter">When</div>}
                                                                {(conditionGroupIndex === 1) && <div className="col-1 ">
                                                                    <Field
                                                                        as="select"
                                                                        name={`conditions.conditions[${conditionIndex}].logicalOperator`}
                                                                        className="form-control px-0">
                                                                        {logicalOperatorOptions.map(option => (
                                                                            <option key={option} value={option}>{option}</option>
                                                                        ))}
                                                                    </Field>
                                                                </div>}
                                                                {(conditionGroupIndex > 1) && <div className="col-1 mt-2 pl-3">
                                                                    {conditionGroup.logicalOperator}
                                                                </div>}
                                                                {(conditionGroupEntry.conditionType === conditionTypes.condition) ?
                                                                    <>
                                                                        <RoutingRuleConditionEditRow conditionFieldName={`conditions.conditions[${conditionIndex}].conditions[${conditionGroupIndex}]`} />
                                                                        <div className="col-1 form-group mb-3">
                                                                            <button
                                                                                type="button"
                                                                                className="form-control border-0 bg-transparent col-3 text-bold text-left pl-0"
                                                                                onClick={() => {
                                                                                    if (conditionGroup.conditions?.length === 1) {
                                                                                        removeFromTrigger(conditionIndex)
                                                                                    } else {
                                                                                        removeFromConditionGroup(conditionGroupIndex)
                                                                                    }
                                                                                }}
                                                                            >
                                                                                X
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                    : <></>
                                                                }
                                                            </div>
                                                        )}
                                                        <button
                                                            type="button"
                                                            className="text-primary border-0 bg-transparent"
                                                            onClick={() => addToConditionGroup(getNewCondition())}>
                                                            <u>Add condition</u>
                                                        </button>

                                                    </div>
                                                )
                                            }}
                                        </FieldArray>
                                    }
                                </div>
                            )
                            }
                            <div className="d-flex mt-3">
                                <button
                                    type="button"
                                    className="text-primary border-0 bg-transparent"
                                    onClick={() => addToTrigger(getNewCondition())}>
                                    <u>Add condition</u>
                                </button>
                                <button
                                    type="button"
                                    className="text-primary border-0 bg-transparent"
                                    onClick={() => addToTrigger(getNewConditionGroup())}>
                                    <u>Add condition group</u>
                                </button>
                            </div>
                        </div>)
                }}
            </FieldArray >
        </div >
    )
}

export default RoutingRuleTriggerEditBlock