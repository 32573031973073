import React, { createContext, ReactNode, useState, useContext } from "react";
import { conditionTypes, IRoutingRule, IRoutingRuleSet } from "./models/RoutingRuleSetModel";
import { shallowEqual, useSelector } from "react-redux";
import { RoutingRulesData } from "./redux/RoutingRulesSlice";

export interface routingRuleContextProps extends RoutingRulesData {
    selectedRoutingRule: SelectedRoutingRule,
    setSelectedRoutingRule: (selectedRoutingRule: SelectedRoutingRule) => void,
    uiEvents: RoutingRuleUIEvents
}

type RoutingRuleUIEvents = {
    showRuleDetailsModal: () => void,
    hideRuleDetailsModal: () => void,
    showRuleEditModal: () => void,
    hideRuleEditModal: () => void,
    showTestCasePage: () => void,
    showRoutingRulesPage: () => void
}

export enum SelectionReason {
    Default = "default",
    New = "new",
    Clone = "clone"
}

type SelectedRoutingRule = {
    routingRule: IRoutingRule,
    selectionReason: SelectionReason
}

const initialRoutingRule = {
    id: "",
    name: "",
    sequenceNumber: 0,
    conditions: {
        conditionType: conditionTypes.conditionGroup
    },
    decision: []
}

const initialSelection = {
    routingRule: initialRoutingRule,
    selectionReason: SelectionReason.Default
} as SelectedRoutingRule

const initialRoutingRuleSet = {
} as IRoutingRuleSet

const initialState: routingRuleContextProps = {
    apiCallInProgress: false,
    alert: null,
    changedRoutingRules: [],
    routingRuleSet: initialRoutingRuleSet,
    selectedRoutingRule: initialSelection,
    setSelectedRoutingRule: () => { },
    testCases: [],
    uiEvents: {
        showRuleDetailsModal: () => { },
        hideRuleDetailsModal: () => { },
        showRuleEditModal: () => { },
        hideRuleEditModal: () => { },
        showTestCasePage: () => { },
        showRoutingRulesPage: () => { }
    }
};

const RoutingRuleContext = createContext<routingRuleContextProps>(initialState);

export function useRoutingRuleContext() {
    return useContext(RoutingRuleContext);
}

type Props = {
    children: ReactNode,
    routingRulesUIEvents: RoutingRuleUIEvents
};

const RoutingRuleProvider = (props: Props) => {
    const [selectedRoutingRule, setSelectedRoutingRule] = useState<SelectedRoutingRule>(initialSelection);

    const { routingRulesData } = useSelector(
        (state: any) => ({
            routingRulesData: state.routingRulesData as RoutingRulesData
        }),
        shallowEqual
    );

    const value = {
        ...routingRulesData,
        selectedRoutingRule,
        setSelectedRoutingRule,
        uiEvents: props.routingRulesUIEvents
    };
    return <RoutingRuleContext.Provider value={value}>{props.children}</RoutingRuleContext.Provider>;
}

export default RoutingRuleProvider;