type Props = {
}

const TestCasesActionCard = (props: Props) => {
    const handleAddTestCase = () => {
    }

    const handleRunTestCases = () => {
    }

    return (
        <div className="d-flex justify-content-between bg-secondary rounded p-3">
            <div className="d-flex width-set-85">
                <button className="border-0 bg-white rounded width-set-15" onClick={handleRunTestCases}>
                    Run TestCases
                </button>
            </div>
            <button className="border-0 bg-white rounded width-set-15" onClick={handleAddTestCase}>
                <div className="d-flex justify-content-center align-content-center">
                    <div className="h1 text-primary mt-2">+</div>
                    <div className="ml-3 mt-4">Add new Test Case</div>
                </div>
            </button>
        </div>
    )
}

export default TestCasesActionCard