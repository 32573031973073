import BootstrapTable, { SortOrder } from "react-bootstrap-table-next";
import { useRoutingRuleContext } from "../../RoutingRuleContext";
import { TestCase, TestContext, TestProduct } from "../../models/TestSuiteModel";
import moment from "moment";
import DeleteIconWithCopy from "../../../svg/DeleteIconWithCopy";
import EditIconWithCopy from "../../../svg/EditIconWithCopy";
import CloneIconWithCopy from "../../../svg/CloneIconWithCopy";
import { sortCaret } from "../../../../_metronic/_helpers";

type Props = {
}

const deriveArticleType = (articleCode: string) => {
    return "ArticleType unknown"
}

const TestCasesTable = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext();
    const apiCallInProgress = routingRulesContext.apiCallInProgress
    const testcases = routingRulesContext.testCases

    const columns = [
        {
            dataField: 'testProduct.channelName',
            text: 'Channel',
            sort: true,
            sortCaret: sortCaret,
            classes: 'col-1',
        },
        {
            dataField: 'testProduct',
            text: 'Address',
            formatter: (cell: TestProduct, row: TestCase) => (
                <div className="d-flex flex-column">
                    {cell.receiverName && cell.receiverName !== "" && <div>{cell.receiverName}</div>}
                    {cell.destinationPostCode && cell.destinationPostCode !== "" && <div>{cell.destinationPostCode}</div>}
                    <div>{cell.destinationCountry}</div>
                </div>
            ),
            sort: true,
            sortCaret: sortCaret,
            sortValue: (cell: TestProduct, row: TestCase) => {
                const result = {
                    destinationCountry: cell.destinationCountry ?? '',
                    destinationPostCode: cell.destinationPostCode ?? '',
                    receiverName: cell.receiverName ?? ''
                }
                return JSON.stringify(result)
            },
            sortFunction: (a: any, b: any, order: SortOrder) => {
                let first = JSON.parse(a)
                const second = JSON.parse(b)

                if (first.destinationCountry !== second.destinationCountry) {
                    if (order === 'desc') {
                        return second.destinationCountry.localeCompare(first.destinationCountry)
                    } else {
                        return first.destinationCountry.localeCompare(second.destinationCountry)
                    }
                }

                if (first.destinationPostCode !== second.destinationPostCode) {
                    if (order === 'desc') {
                        return second.destinationPostCode.localeCompare(first.destinationPostCode)
                    } else {
                        return first.destinationPostCode.localeCompare(second.destinationPostCode)
                    }
                }

                if (order === 'desc') {
                    return second.receiverName.localeCompare(first.receiverName)
                } else {
                    return first.receiverName.localeCompare(second.receiverName)
                }
            },
            classes: 'col-1',
        },
        {
            dataField: 'testProduct.deliveryType',
            text: 'Delivery',
            formatter: (cell: string, row: TestCase) => (
                <div className="d-flex flex-column">
                    {row.testProduct.deliveryType && row.testProduct.deliveryType !== "" && <div>{row.testProduct.deliveryType}</div>}
                    {row.testProduct.deliveryMethod && row.testProduct.deliveryMethod !== "" && <div>{row.testProduct.deliveryMethod}</div>}
                </div>
            ),
            sort: true,
            sortValue: (cell: string, row: TestCase) => {
                const result = {
                    deliveryType: row.testProduct.deliveryType ?? '',
                    deliveryMethod: row.testProduct.deliveryMethod ?? ''
                }
                return JSON.stringify(result)
            },
            sortFunction: (a: any, b: any, order: SortOrder) => {
                let first = JSON.parse(a)
                const second = JSON.parse(b)

                if (first.deliveryType !== second.deliveryType) {
                    if (order === 'desc') {
                        return second.deliveryType.localeCompare(first.deliveryType)
                    } else {
                        return first.deliveryType.localeCompare(second.deliveryType)
                    }
                }

                if (order === 'desc') {
                    return second.deliveryMethod.localeCompare(first.deliveryMethod)
                } else {
                    return first.deliveryMethod.localeCompare(second.deliveryMethod)
                }
            },
            sortCaret: sortCaret,
            classes: 'col-1',
        },
        {
            dataField: 'testProduct.articleCode',
            text: 'Product',
            formatter: (cell: string, row: TestCase) => (
                <div className="d-flex flex-column">
                    <div>{deriveArticleType(cell)}</div>
                    <div>{cell}</div>
                </div>
            ),
            sort: true,
            sortCaret: sortCaret,
            sortValue: (cell: string, row: TestCase) => {
                const result = {
                    articleCode: cell,
                    articleType: deriveArticleType(cell)
                }
                return JSON.stringify(result)
            },
            sortFunction: (a: any, b: any, order: SortOrder) => {
                let first = JSON.parse(a)
                const second = JSON.parse(b)

                if (first.articleType !== second.articleType) {
                    if (order === 'desc') {
                        return second.articleType.localeCompare(first.articleType)
                    } else {
                        return first.articleType.localeCompare(second.articleType)
                    }
                }

                if (order === 'desc') {
                    return second.articleCode.localeCompare(first.articleCode)
                } else {
                    return first.articleCode.localeCompare(second.articleCode)
                }
            },
            classes: 'col-2',
        },
        {
            dataField: 'testProduct.productOptions',
            text: 'Options',
            formatter: (cell: Object, row: TestCase) => {
                const options = Object.entries(cell).sort()
                return (
                    <div className="d-flex">
                        <div className="d-flex flex-column">
                            {options.map((kvp, index) => (
                                <div key={index} >{kvp[0]}</div>
                            ))}
                        </div>
                        <div className="d-flex flex-column ml-3">
                            {options.map((kvp, index) => (
                                <div key={index} >{kvp[1]}</div>
                            ))}
                        </div>
                    </div>
                )
            },
            sort: false,
            classes: 'col-2',
        },
        {
            dataField: 'testContext',
            text: 'TestContext',
            formatter: (cell: TestContext, row: TestCase) => {
                let formattedDate = ''
                if (cell?.simulatedEvaluationDate) {
                    formattedDate = moment(cell.simulatedEvaluationDate).format("YYYY-MM-DD hh:mm")
                }
                return (
                    <div className="d-flex">
                        <div className="d-flex flex-column">
                            {cell?.simulatedEvaluationDate && <div>Date</div>}
                            {cell?.simulatedRandomValue && <div>Random</div>}
                        </div>
                        <div className="d-flex flex-column ml-3">
                            {cell?.simulatedEvaluationDate && <div>{formattedDate}</div>}
                            {cell?.simulatedRandomValue && <div>{cell.simulatedRandomValue}</div>}
                        </div>
                    </div>
                )
            },
            sort: false,
            classes: 'col-2',
        },
        {
            dataField: 'targetProductionSite',
            text: 'Factory',
            sort: true,
            sortCaret: sortCaret,
            classes: 'col-1',
        },
        {
            dataField: 'actions',
            text: 'Actions',
            formatter: (cell: any, row: TestCase) => (
                <div className='table-action-icons-container-leftaligned'>
                    <button
                        onClick={() => { }}
                        className='table-action-icons-container-button-small'
                    >
                        <EditIconWithCopy
                            copy='Edit'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                    <button
                        onClick={() => { }}
                        className='table-action-icons-container-button-small'
                    >
                        <CloneIconWithCopy
                            copy='Clone'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                    <button
                        onClick={() => { }}
                        className='table-action-icons-container-button-small'
                    >
                        <DeleteIconWithCopy
                            copy='Delete'
                            customStyle='table-action-icons-children-small'
                        />
                    </button>
                </div>
            ),
            sort: false,
            classes: 'col-1'
        },
    ]

    return (
        <>
            <BootstrapTable
                wrapperClasses='table-responsive'
                bordered={false}
                classes='table table-head-custom table-vertical-center overflow-hidden semi-bold'
                rowClasses={() => ""}
                bootstrap4
                keyField='id'
                data={testcases}
                columns={columns}
                onTableChange={() => { }}
            >
            </BootstrapTable>
            {apiCallInProgress && (!testcases || testcases.length === 0) &&
                <div className='d-flex justify-content-center'>
                    <div className="overlay-layer bg-transparent">
                        <div className="spinner spinner-lg spinner-success" />
                    </div>
                </div>
            }
        </>
    );
}

export default TestCasesTable;