import { AxiosError } from 'axios';
import { getRoutingRules, getTestCases, postRoutingRules } from '../api/RoutingRulesApi';
import { IRoutingRule, IRoutingRuleSet } from '../models/RoutingRuleSetModel';
import { routingRulesSlice, callTypes } from "./RoutingRulesSlice";
import { RoutingRulesAlert } from '../models/RoutingRulesAlert';

const { actions: sliceActions } = routingRulesSlice;

export const fetchRoutingRules = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getRoutingRules()
            .then(response => {
                const routingRuleSet = response
                dispatch(sliceActions.routingRulesFetched(routingRuleSet));
                dispatch(sliceActions.clearRoutingRuleChanges());
            })
            .catch(err => {
                const error = err as AxiosError
                const alert = {
                    message: "Failure on GET for Routing Rules",
                    apiResponseCode: error.response?.status,
                    apiMessage: error.message,
                    url: error.config?.url
                } as RoutingRulesAlert
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };

export const persistRoutingRuleSet = (ruleSet: IRoutingRuleSet, description: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.post }));
        const updatedRuleSet = {
            ...ruleSet,
            description: description
        }
        return postRoutingRules(updatedRuleSet)
            .then(response => {
                const routingRuleSet = response
                dispatch(sliceActions.routingRulesFetched(routingRuleSet));
                dispatch(sliceActions.clearRoutingRuleChanges());
            })
            .catch(err => {
                const error = err as AxiosError
                const alert = {
                    message: "Failure on POST for Routing Rules",
                    apiResponseCode: error.response?.status,
                    apiMessage: error.message,
                    url: error.config?.url
                } as RoutingRulesAlert
                dispatch(sliceActions.catchError({ alert, callType: callTypes.post }));
            });
    };

export const fetchTestCases = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.startCall({ callType: callTypes.get }));
        return getTestCases()
            .then(response => {
                const testCases = response
                dispatch(sliceActions.testCasesFetched(testCases));
            })
            .catch(err => {
                const error = err as AxiosError
                const alert = {
                    message: "Failure on GET for Test Cases",
                    apiResponseCode: error.response?.status,
                    apiMessage: error.message,
                    url: error.config?.url
                } as RoutingRulesAlert
                dispatch(sliceActions.catchError({ alert, callType: callTypes.get }));
            });
    };


export const markRoutingRuleChanged = (ruleId: string, isDeletion: boolean) =>
    async (dispatch: (arg0: any) => void) => {
        const payload = {
            ruleId: ruleId,
            isDeletion: isDeletion
        }
        dispatch(sliceActions.markRoutingRuleChanged(payload));
    };

export const upsertRoutingRule = (rule: IRoutingRule) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.upsertRoutingRule(rule));
    };

export const deleteRoutingRule = (ruleId: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.deleteRoutingRule(ruleId));
    };

export const updateRuleSetDescription = (description: string) =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.updateRuleSetDescription(description));
    };

export const dismissAlert = () =>
    async (dispatch: (arg0: any) => void) => {
        dispatch(sliceActions.dismissAlert());
    };