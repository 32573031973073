import { useEffect, useState } from "react";
import RoutingRulesCard from "../components/RoutingRulesCard";
import RoutingRuleProvider, { useRoutingRuleContext } from "../RoutingRuleContext";
import RoutingRuleDetailModal from "../components/detailModal/RoutingRuleDetailModal";
import RoutingRuleEditModal from "../components/editModal/RoutingRuleEditModal";
import RoutingRulesSaveCard from "../components/RoutingRulesSaveCard";
import { useDispatch } from "react-redux";
import { Alert } from "react-bootstrap";
import * as actions from '../redux/RoutingRulesActions'
import TestCasesCard from "../components/testcases/TestCasesCard";

const RoutingRulesPage = () => {
  const [showRuleDetailsModal, setShowRuleDetailsModal] = useState<boolean>(false);
  const [showRuleEditModal, setShowRuleEditModal] = useState<boolean>(false);
  const [showTestCasesPage, setTestCasesPage] = useState<boolean>(false);
  const routingRuleContext = useRoutingRuleContext();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.fetchRoutingRules());
    dispatch(actions.fetchTestCases());
  }, [dispatch]);

  const alert = routingRuleContext.alert

  const handleDismissAlert = () => {
    dispatch(actions.dismissAlert())
  }

  const routingRulesUIEvents = {
    showRuleDetailsModal: () => { setShowRuleDetailsModal(true) },
    hideRuleDetailsModal: () => { setShowRuleDetailsModal(false) },
    showRuleEditModal: () => { setShowRuleEditModal(true) },
    hideRuleEditModal: () => { setShowRuleEditModal(false) },
    showTestCasePage: () => { setTestCasesPage(true) },
    showRoutingRulesPage: () => { setTestCasesPage(false) }
  }

  return (
    <RoutingRuleProvider routingRulesUIEvents={routingRulesUIEvents}>
      {alert && alert?.message && (
        <Alert variant="danger" onClose={handleDismissAlert} dismissible>
          <Alert.Heading>{alert.message}</Alert.Heading>
          {alert.url && <div>{`Call to ${alert.url}`}</div>}
          {alert.apiMessage && <div>{`Response: ${alert.apiMessage}`}</div>}
          {alert.apiResponseCode && <div>{`ResponseCode: ${alert.apiResponseCode}`}</div>}
        </Alert>
      )}
      <RoutingRulesSaveCard />
      {showTestCasesPage &&
        <>
          <TestCasesCard />
        </>}
      {!showTestCasesPage &&
        <>
          <RoutingRulesCard />
          {showRuleDetailsModal && <RoutingRuleDetailModal />}
          {showRuleEditModal && <RoutingRuleEditModal />}
        </>
      }
    </RoutingRuleProvider>
  )
}

export { RoutingRulesPage };