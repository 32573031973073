import { activityRuleTypes, conditionTypes, logicalOperators } from '../models/RoutingRuleSetModel';
import { SelectionReason, useRoutingRuleContext } from '../RoutingRuleContext';

type Props = {
    filterProps?: any;
}

const RoutingRulesActionCard = (props: Props) => {
    const routingRulesContext = useRoutingRuleContext();

    const handleAddRuleClick = () => {
        const routingRule = {
            id: crypto.randomUUID(),
            name: "",
            sequenceNumber: -1, //Will be updated to be last in the list
            activityRules: [{
                activityRuleType: activityRuleTypes.unconditionalActivationRule,
                active: false
            }],
            conditions: {
                conditionType: conditionTypes.conditionGroup,
                logicalOperator: logicalOperators.and,
                conditions: []
            },
            decision: [{
                weight: 100,
                productionSite: ""
            }]
        }
        routingRulesContext.setSelectedRoutingRule({ routingRule: routingRule, selectionReason: SelectionReason.New })
        routingRulesContext.uiEvents.showRuleEditModal()
    }

    return (
        <div className="d-flex justify-content-between bg-secondary rounded p-3">
            <div className="d-flex width-set-85">
                {/* <input className="width-set-50 border-0 rounded pl-3" type="text" placeholder="SOON!!! filter on factory, condition variable" />
                <div className="ml-3 mt-3"><u>Advanced</u></div> */}
            </div>
            <button className="border-0 bg-white rounded width-set-15" onClick={handleAddRuleClick}>
                <div className="d-flex justify-content-center align-content-center">
                    <div className="h1 text-primary mt-2">+</div>
                    <div className="ml-3 mt-4">Add new rule</div>
                </div>
            </button>
        </div>
    )
}

export default RoutingRulesActionCard